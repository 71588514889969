var SKRYPTY = {

    clipboard: function () {
        $.notify.addStyle('correct', {
            html: "<div><i class='icon-schowek' style='margin-right: 10px'></i> <span data-notify-html/></div>",
            classes: {
                base: {
                    "white-space": "nowrap",
                    "background-color": "#d5dce1",
                    "color": "#231e45",
                    "padding": "10px 15px",
                    "border-radius": "3px",
                    "text-align": "center"
                }
            }
        });

        $.notify.addStyle('warning', {
            html: "<div><span data-notify-html/></div>",
            classes: {
                base: {
                    "white-space": "nowrap",
                    "background-color": "#87868e",
                    "color": "#d5dce1",
                    "padding": "10px 15px",
                    "border-radius": "3px",
                    "text-align": "center"
                }
            }
        });

        function addToClipboard(element) {
            var clipboardItems = Cookies.getJSON('clipboardItems');

            var $this = element;
            var url = $this.attr('data-url');
            var title = $this.attr('data-title');
            var description = $this.attr('data-description');
            var image = $this.attr('data-image');
            var id = $this.attr('data-id');

            if (typeof clipboardItems === 'undefined') {
                clipboardItems = {};
            }

            if (typeof clipboardItems[id] === 'undefined') {
                clipboardItems[id] = {};

                clipboardItems[id] = {
                    'url': url,
                    'title': title,
                    'description': description,
                    'image': image
                };

                if ($('html').attr('lang') == 'pl') {
                    $.notify('Dodano ofertę do schowka. <a href="javascript:void(0)" class="show-clipboard"><u>Pokaż schowek</u></a>',
                        {
                            position: 'right bottom',
                            style: 'correct'
                        }
                    );
                } else {
                    $.notify('The offer has been added to the clipboard. <a href="javascript:void(0)" class="show-clipboard"><u>Show clipboard</u></a>',
                        {
                            position: 'right bottom',
                            style: 'correct'
                        }
                    );
                }

                $(document).on('click', '.show-clipboard', function () {
                    $('.header-clipboard').addClass('open');
                });

                Cookies.set('clipboardItems', clipboardItems);
                checkClipboard();
            } else {
                removeFromClipboard(element);
                if ($('html').attr('lang') == 'pl') {
                    $.notify("Oferta została usunięta z schowka.",
                        {
                            position: 'right bottom',
                            style: 'warning'
                        }
                    );
                } else {
                    $.notify("The offer has been removed from the clipboard.",
                        {
                            position: 'right bottom',
                            style: 'warning'
                        }
                    );
                }
            }
        }

        function removeFromClipboard(element) {
            var clipboardItems = Cookies.getJSON('clipboardItems');
            var $this = element;
            var id = $this.attr('data-id');
            $('.offerbox-add[data-id="' + id + '"]').removeClass('added');
            $('.details-add[data-id="' + id + '"]').removeClass('added');
            if ($('html').attr('lang') == 'pl') {
                $('.offerbox-add[data-id="' + id + '"]').attr('data-original-title', "Dodaj do schowka");
            } else {
                $('.offerbox-add[data-id="' + id + '"]').attr('data-original-title', "Add to clipboard");
            }
            delete clipboardItems[id];
            Cookies.set('clipboardItems', clipboardItems);
            $('.item-delete[data-id="' + id + '"]').closest('li').remove();
            if (Object.keys(clipboardItems).length == 0) {
                $('.header-clipboard-counter').text('0');
                $('.header-clipboard').removeClass('has-items');
                $('.header-clipboard-details').removeClass('has-items');
            } else {
                $('.header-clipboard-counter').text(Object.keys(clipboardItems).length);
            }
        }

        function checkClipboard() {
            var clipboardItems = Cookies.getJSON('clipboardItems');
            if (typeof clipboardItems === 'undefined') {
                $('.header-clipboard-counter').text('0');
                $('.header-clipboard').removeClass('has-items');
                $('.header-clipboard-details').removeClass('has-items');
            } else if (Object.keys(clipboardItems).length == 0) {
                $('.header-clipboard-counter').text('0');
                $('.header-clipboard').removeClass('has-items');
                $('.header-clipboard-details').removeClass('has-items');
            } else {
                $('.header-clipboard-list').html('');

                Object.keys(clipboardItems).forEach(function (key) {
                    var template = '<li class="item">' +
                        '                                    <a href="@@url1" class="item-image"' +
                        '                                         style="background-image: url(@@image);"></a>' +
                        '                                    <a href="@@url2" class="item-text">@@title <br/> @@description</a>' +
                        '                                    <i class="item-delete icon-usun" data-id="@@index"></i>' +
                        '                                </li>';

                    template = template.replace("@@url1", clipboardItems[key].url);
                    template = template.replace("@@url2", clipboardItems[key].url);
                    template = template.replace("@@image", clipboardItems[key].image);
                    template = template.replace("@@title", clipboardItems[key].title);
                    template = template.replace("@@description", clipboardItems[key].description);
                    template = template.replace("@@index", key);

                    $('.offerbox-add[data-id="' + key + '"]').addClass('added');
                    $('.details-add[data-id="' + key + '"]').addClass('added');
                    if ($('html').attr('lang') == 'pl') {
                        $('.offerbox-add[data-id="' + key + '"]').attr('data-original-title', "Dodano do schowka");
                    } else {
                        $('.offerbox-add[data-id="' + key + '"]').attr('data-original-title', "Added to clipboard");
                    }

                    $('.header-clipboard-list').append(template);
                });

                $('.header-clipboard-counter').text(Object.keys(clipboardItems).length);

                $('.header-clipboard').addClass('has-items');
                $('.header-clipboard-details').addClass('has-items');

                $(document).on('click', '.item-delete', function () {
                    var $this = $(this);
                    removeFromClipboard($this);
                });
            }
        }

        $('.header-clipboard').mouseenter(function () {
            $(this).removeClass('open');
        });

        $('.offerbox-add, .details-add').click(function (e) {
            e.preventDefault();
            var $this = $(this);
            addToClipboard($this);
        });

        $('.item-delete').click(function () {
            var $this = $(this);
            removeFromClipboard($this);
        });

        $('.offerbox-add').tooltip();

        checkClipboard();
    },
    contactForm: function () {
        $('.header-clipboard-link, .contact-modal-button').click(function () {
            var clipboardList = $('.header-clipboard-list').prop('outerHTML');
            if($('.header-clipboard-list').children().length) {
                $('.contact-modal .clipboard-offers').removeClass('hidden');
            } else {
                $('.contact-modal .clipboard-offers').addClass('hidden');
            }
            $('.contact-modal .clipboard-offers').html(clipboardList);
            var offerIDs = '';
            $('.contact-modal .item-delete').each(function () {
                offerIDs = offerIDs + $(this).attr('data-id') + '; '
            });
            $('.contact-modal .clipboard-input input').attr('value', offerIDs);
        });

        $('.toggle-link').click(function () {
            $('.toggle-text').toggleClass('toggled');
        })

        $('form.contact-form').submit(function (e) {
            var $this = $(this);
            if ($this.data('ajax') != undefined && $this.data('ajax')) {
                $this.find('.alert-form-js').remove();

                var form = $(this);
                e.preventDefault();
                var url = form.attr('action');

                var form_data = JSON.stringify(form.serializeArray());

                form.find('input[type="submit"]').attr('disabled', true);
                form.find('button[type="submit"]').attr('disabled', true);

                $.ajax({
                    url: url,
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {'dane': form_data},
                    type: 'post',
                    dataType: 'json',
                    success: function (ret) {
                        form.find('input[type="submit"]').attr('disabled', false);
                        form.find('button[type="submit"]').attr('disabled', false);

                        var alert_class = 'alert alert-success alert-form-js';
                        if (!ret.success)
                            alert_class = 'alert alert-danger alert-form-js';

                        var error_container = $('<div>').addClass(alert_class);
                        var list = $('<ul>');

                        if (ret.success) {
                            if (form.data('callback') != undefined && form.data('callback') != '') {
                                if (__is_function__(form.data('callback')))
                                    window[form.data('callback')](ret, form);
                            }
                            list.append('<li>' + ret.info + '</li>');
                        } else {
                            for (i in ret.info) {
                                list.append('<li>' + ret.info[i] + '</li>');
                            }
                        }
                        error_container.append(list);
                        if (form.find('.alert-box').length) {
                            if (!ret.success) {
                                $('.alert-box').prepend(error_container);
                            } else {
                                form.prepend(error_container);
                            }
                        } else {
                            form.prepend(error_container);
                        }
                    }
                })
            }
        });
    },
    header: function () {
        $(window).scroll(function () {
            var offsetTop = $(document).scrollTop();
            if (offsetTop > 100) {
                $('.header').addClass('small-logo');
            } else {
                $('.header').removeClass('small-logo');
            }
        });
    },
    jumbotron: function () {
        var $homepageCarousel = $('.homepage-jumbotron-carousel').owlCarousel({
            autoplay: true,
            autoplayTimeout: 8000,
            autoplayHoverPause: true,
            items: 1,
            dots: false,
            loop: true,
            mouseDrag: false,
            touchDrag: false,
            nav: true,
            navContainer: '.homepage-jumbotron-nav',
            navText: ['<i class="icon-lewo"></i>', '<i class="icon-prawo"></i>'],
            animateOut: 'fadeUp-out',
            animateIn: 'fadeUp-in',
            onInitialized: function () {
                $('.owl-item:not(.active)').removeClass('active-box');
                $('.owl-item.active').addClass('active-box');
            },
            onTranslated: function () {
                $('.owl-item:not(.active)').removeClass('active-box');
                $('.owl-item.active').addClass('active-box');
            }
        });

        $homepageCarousel.on('changed.owl.carousel', function(e) {
            $homepageCarousel.trigger('stop.owl.autoplay');
            $homepageCarousel.trigger('play.owl.autoplay');
        });

        var $backgroundCarousel = $('.details-jumbotron-backgroundcarousel').owlCarousel({
            autoplay: false,
            items: 1,
            dots: false,
            mouseDrag: true,
            touchDrag: true,
            animateOut: 'fadeUp-out',
            animateIn: 'fadeUp-in',
            nav: true,
            navContainer: '.top-nav',
            navText: ['<i class="icon-lewo"></i>', '<i class="icon-prawo"></i>'],
            onInitialized: function () {
                $('.owl-item:not(.active)').removeClass('active-box');
                $('.owl-item.active').addClass('active-box');
            },
            onTranslated: function () {
                $('.owl-item:not(.active)').removeClass('active-box');
                $('.owl-item.active').addClass('active-box');

                $('.background-active').removeClass('background-active');
                var index = $('.details-jumbotron-backgroundcarousel').find('.owl-item.active').index();
                $('.details-jumbotron-carousel').find('.owl-item').eq(index).addClass('background-active');
                if(!$('.background-active').hasClass('active')){
                    $thumbnailCarousel.trigger('to.owl.carousel', index);
                }
            }
        });

        var $thumbnailCarousel = $('.details-jumbotron-carousel').owlCarousel({
            items: 4,
            dots: false,
            margin: 30,
            mouseDrag: true,
            nav: true,
            navContainer: '.bottom-nav',
            navText: ['<i class="icon-lewo"></i>', '<i class="icon-prawo"></i>'],
            responsive: {
                1200: {
                    items: 4
                },
                992: {
                    items: 3
                },
                768: {
                    items: 3
                },
                480: {
                    items: 2
                },
                0: {
                    items: 1
                }
            },
            onInitialized: function (event) {
                $('.details-jumbotron-carousel .owl-item.active').first().addClass('background-active');
            },
        });

        $('.details-jumbotron-item').click(function () {
            $('.background-active').removeClass('background-active');
            var index = $(this).closest('.owl-item').index();
            $backgroundCarousel.trigger('to.owl.carousel', index);
        });
    },
    magnificPopup: function () {
        var $detailsSlides;

        if($(window).width() <= 479) {
            $detailsSlides = $('.details-jumbotron-item');
        } else {
            $detailsSlides = $('.details-jumbotron-background');
        }

        $detailsSlides.magnificPopup({
            type: 'image',
            removalDelay: 300,
            mainClass: 'mfp-fade',
            tClose: 'Zamknij (Esc)',
            tLoading: 'Wczytywanie...',
            gallery: {
                tPrev: 'Poprzedni (Lewy klawisz)',
                tNext: 'Następny (Prawy klawisz)',
                tCounter: '%curr% z %total%',
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            callbacks: {
                elementParse: function (item) {
                    item.src = item.el.attr('data-image');
                }
            },
            image: {
                tError: '<a href="%url%">Obrazek</a> nie mogł zostać załadowany.',
                verticalFit: true,
                titleSrc: function (item) {
                    return item.el.data('title');
                }
            },
            ajax: {
                tError: '<a href="%url%">Połączenie</a> odrzucono.'
            }
        });

        $('.magnific-popup').magnificPopup({
            type: 'image',
            removalDelay: 300,
            mainClass: 'mfp-fade',
            tClose: 'Zamknij (Esc)',
            tLoading: 'Wczytywanie...',
            gallery: {
                tPrev: 'Poprzedni (Lewy klawisz)',
                tNext: 'Następny (Prawy klawisz)',
                tCounter: '%curr% z %total%',
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">Obrazek</a> nie mogł zostać załadowany.',
                verticalFit: true,
                titleSrc: function (item) {
                    return item.el.data('title');
                }
            },
            ajax: {
                tError: '<a href="%url%">Połączenie</a> odrzucono.'
            }
        });

        $('.subpage-thumb').magnificPopup({
            delegate: 'img',
            type: 'image',
            removalDelay: 300,
            mainClass: 'mfp-fade',
            tClose: 'Zamknij (Esc)',
            tLoading: 'Wczytywanie...',
            gallery: {
                tPrev: 'Poprzedni (Lewy klawisz)',
                tNext: 'Następny (Prawy klawisz)',
                tCounter: '%curr% z %total%',
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">Obrazek</a> nie mogł zostać załadowany.',
                verticalFit: true,
                titleSrc: function (item) {
                    return item.el.data('title');
                }
            },
            ajax: {
                tError: '<a href="%url%">Połączenie</a> odrzucono.'
            },
            callbacks: {
                elementParse: function (item) {
                    item.src = item.el.attr('src');
                }
            }
        });
    },
    menu: function () {
        var hash = window.location.hash;
        var $id = $(hash);
        if ($id.length !== 0) {
            if (hash == '#sprzedaz' || hash == '#sale') {
                $('.filter-button').removeClass('active');
                $('.filter-button[data-type="sale"]').addClass('active');
                $('.filter-container').addClass('hidden');
                $('.filter-container[data-type="sale"]').removeClass('hidden');
                var pos = $id.offset().top;
                var headerHeight = $('.header').height();
                $('body, html').animate({scrollTop: pos - headerHeight});
            } else if (hash == '#wynajem' || hash == '#rent') {
                $('.filter-button').removeClass('active');
                $('.filter-button[data-type="rental"]').addClass('active');
                $('.filter-container').addClass('hidden');
                $('.filter-container[data-type="rental"]').removeClass('hidden');
                var pos = $id.offset().top;
                var headerHeight = $('.header').height();
                $('body, html').animate({scrollTop: pos - headerHeight});
            }
        }

        $('.header-menu a[href*="#"], .header-mobile-menu a[href*="#"]').click(function (e) {
            var id = '#' + $(this).attr('href').split('#')[1];
            var $id = $(id);
            if ($id.length === 0) {
                return;
            }
            e.preventDefault();
            history.pushState(null, null, id);
            var pos = $id.offset().top;
            var headerHeight = $('.header').height();
            $('body, html').animate({scrollTop: pos - headerHeight});

            if (id == '#sprzedaz' || id == '#sale') {
                $('.filter-button').removeClass('active');
                $('.filter-button[data-type="sale"]').addClass('active');
                $('.filter-container').addClass('hidden');
                $('.filter-container[data-type="sale"]').removeClass('hidden');
            } else if (id == '#wynajem' || id == '#rent') {
                $('.filter-button').removeClass('active');
                $('.filter-button[data-type="rental"]').addClass('active');
                $('.filter-container').addClass('hidden');
                $('.filter-container[data-type="rental"]').removeClass('hidden');
            }

        });
    },
    offercarousel: function () {
        $('.offercarousel').owlCarousel({
            items: 4,
            dots: true,
            loop: false,
            nav: false,
            margin: 30,
            responsive: {
                1200: {
                    items: 4
                },
                992: {
                    items: 3
                },
                768: {
                    items: 3
                },
                480: {
                    items: 2
                },
                0: {
                    items: 1
                }
            }
        });
    },
    search: function () {
        function ajaxSearch() {
            var form_data = {};
            $.each($('.search-form').serializeArray(), function () {
                form_data[this.name] = this.value;
            });

            var template = '<div class="col-ms-12 col-xs-6 col-md-4 col-lg-3 offerbox-column">\n' +
                '                            <a href="@@url" class="offerbox">\n' +
                '                                <div class=" offerbox-image" style="background-image: url(@@image);">\n' +
                '                                    <button class="global-button offerbox-button">@@details</button>\n' +
                '                                </div>\n' +
                '                                <div class="offerbox-details">\n' +
                '                                    <div class="offerbox-header">\n' +
                '                                        <div class="offerbox-title">@@title</div>\n' +
                '                                        <div class="offerbox-description">@@description</div>\n' +
                '                                    </div>\n' +
                '                                    <div class="offerbox-footer">\n' +
                '                                        <div class="offerbox-price">@@price @@currency</div>\n' +
                '                                        <i class="icon-dodaj offerbox-add" title="@@clipboard_text" data-url="@@url" data-title="@@title" data-description="@@description" data-image="@@image" data-id="@@id"></i>\n' +
                '                                    </div>\n' +
                '                                </div>\n' +
                '                            </a>\n' +
                '                        </div>';

            $.ajax({
                url: '/ajax/searchNieruchomosci',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: form_data,
                type: 'post',
                dataType: 'json',
                success: function (ret) {
                    var locale = $('html').attr('lang') == 'pl' ? '/pl/szczegoly?offer=' : '/en/details?offer=';
                    var details = $('html').attr('lang') == 'pl' ? 'Zobacz szczegóły' : 'See details';
                    var clipboard_text = $('html').attr('lang') == 'pl' ? 'Dodaj do schowka' : 'Add to clipboard';
                    $('.filter-container .row').html('');
                    ret.forEach(function (item, index) {
                        var offer = template;
                        offer = offer.replace(/@@url/g, locale + item.id);
                        offer = offer.replace(/@@image/g, '/img/original/' + item.images[0].zdjecie);
                        offer = offer.replace("@@details", details);
                        offer = offer.replace(/@@title/g, item.locality);
                        offer = offer.replace(/@@description/g, item.street != null ? item.street : '');
                        offer = offer.replace("@@price", parseFloat(item.price).toLocaleString());
                        offer = offer.replace("@@currency", item.currency);
                        offer = offer.replace("@@clipboard_text", clipboard_text);
                        offer = offer.replace("@@id", item.listingId);

                        if(item.type == 'sale') {
                            $('.filter-container[data-type="sale"] .row').append(offer);
                        } else {
                            $('.filter-container[data-type="rental"] .row').append(offer);
                        }
                    });

                    $('.filter-container .row').each(function() {
                        var $this = $(this);
                        var info = $('[data-noresults]').attr('data-noresults');
                        if($this.find('.offerbox-column').length == 0) {
                            $this.html('<div class="col-xs-12 text-center"><div class="global-subtitle__smallerfont">' + info + '</div></div>');
                        }
                    });

                    SKRYPTY.clipboard();
                }
            });
        }

        $('.filter-button').click(function () {
            var $this = $(this);
            if (!$this.hasClass('active')) {
                $('.filter-button').removeClass('active');
                $this.addClass('active');
                var type = $this.attr('data-type');
                //$('input[name="type"]').attr('value', type);
                $('.filter-container').addClass('hidden');
                $('.filter-container[data-type="' + type + '"]').removeClass('hidden');
            }
        });

        $('.filter-button-clear').click(function () {
            $('.filter-button-search, .filter-button-clear').removeClass('show-clear');
            $('.search-form input').each(function () {
                $(this).val('');
            });

            ajaxSearch();
        });

        $('.search-form').submit(function (e) {
            e.preventDefault();

            $('.filter-button-search, .filter-button-clear').removeClass('show-clear');
            $('.search-form input').each(function () {
                var $this = $(this);
                if($this.val() != '') {
                    $('.filter-button-search, .filter-button-clear').addClass('show-clear');
                }
            });

            ajaxSearch();
        });
    },
    wow: function () {
        var wow = new WOW({
            mobile: false
        });

        wow.init();
    }

};

$(document).ready(function () {
    for (var funkcja in SKRYPTY) {
        SKRYPTY[funkcja]();
    }
});

function hide_on_success(res, form) {
    $('.hide-on-success', form).hide();
    $('.show-on-success', form).find('.show-on-success-info').html(res.info);
    $('.show-on-success', form).show();
}

function __is_function__(func) {
    return typeof window[func] !== 'undefined' && $.isFunction(window[func]);
}